import { Injectable } from '@angular/core';
import { Action, NgxsOnInit, Selector, State, StateContext } from '@ngxs/store';
import { CompanyService } from '../service/api/company/company.service';
import { concatMap, EMPTY } from 'rxjs';
import { BaseState } from './base';
import { IndustryService } from '../service/api/industry/industry.service';
export interface industry {
  list: any[];
}

export class FetchIndustry {
  static readonly type = '[IndustryState] FetchIndustry';
}

@State<industry>({
  name: 'industry',
  defaults: {
    list: [],
  },
})
@Injectable()
export class IndustryState extends BaseState implements NgxsOnInit {
  constructor(private industryService: IndustryService) {
    super();
  }
  ngxsOnInit(ctx: StateContext<any>): void {
    // ctx.dispatch(new FetchIndustry());
  }

  @Selector()
  static getList(state: industry): any[] {
    return state.list;
  }

  @Action(FetchIndustry)
  fetchType(states: StateContext<industry>) {
    return this.industryService.getIndustryList().pipe(
      concatMap((res: any) => {
        console.log(res);
        states.patchState({
          list: res.message.industryList,
        });
        return EMPTY;
      })
    );
  }
}

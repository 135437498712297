import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class IndustryService {
  constructor(private http: HttpClient) {}

  getIndustryList() {
    return this.http.post(environment.host + '/industry.php', {
      gofor: 'allIndustries',
    });
  }
}
